<template>
  <div class="card card-statistics h-100">
    <div class="card-header">
      <h4 class="card-title">
        สรุปยอดผู้เล่น
      </h4>
      <p class="card-text font-small-2 mr-25 mb-0">
        อัพเดทล่าสุด: {{ now }}
      </p>
    </div>
    <div class="card-body statistics-body">
      <div class="row">
        <div class="col-sm-6 col-xl-6 mb-2 mb-xl-0">
          <div class="media">
            <div class="media-aside align-self-start">
              <span
                class="b-avatar badge-light-success rounded-circle"
                style="width: 48px; height: 48px"
              ><span
                class="b-avatar-custom"
              >
                <i class="far fa-user-friends fa-2x" /> </span></span>
            </div>
            <div class="media-body my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ data ? data.todayRegisterMembers.total.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }): 0 }}
              </h4>
              <p class="card-text font-small-3 mb-0 text-muted">
                สมาชิกใหม่(วันนี้)
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-xl-6 mb-2 mb-xl-0">
          <div class="media">
            <div class="media-aside align-self-start">
              <span
                class="b-avatar badge-light-info rounded-circle"
                style="width: 48px; height: 48px"
              ><span
                class="b-avatar-custom"
              ><i class="far fa-user-friends fa-2x" /></span></span>
            </div>
            <div class="media-body my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ data ? data.totalRegisterMembers.total.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : 0 }}
              </h4>
              <p class="card-text font-small-3 mb-0 text-muted">
                สมาชิกทั้งหมด
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-xl-6 mb-2 mb-sm-0 mt-2">
          <div class="media">
            <div class="media-aside align-self-start">
              <span
                class="b-avatar badge-light-danger rounded-circle"
                style="width: 48px; height: 48px"
              ><span
                class="b-avatar-custom"
              ><i class="far fa-user-friends fa-2x" /></span></span>
            </div>
            <div class="media-body my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ data ? data.totalDepositMembers.total.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : 0 }}
              </h4>
              <p class="card-text font-small-3 mb-0 text-muted">
                สมาชิกที่ฝากเงินแล้ว
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-xl-6 mt-2">
          <div class="media">
            <div class="media-aside align-self-start">
              <span
                class="b-avatar badge-light-warning rounded-circle"
                style="width: 48px; height: 48px"
              ><span
                class="b-avatar-custom"
              >
                <i class="far fa-user-friends fa-2x" /> </span></span>
            </div>
            <div class="media-body my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ data ? data.totalNoDepositMembers.total.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : 0 }}
              </h4>
              <p class="card-text font-small-3 mb-0 text-muted">
                สมาชิกที่ยังไม่ฝากเงิน
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-xl-6 mt-2">
          <div class="media">
            <div class="media-aside align-self-start">
              <span
                class="b-avatar badge-light-primary rounded-circle"
                style="width: 48px; height: 48px"
              ><span
                class="b-avatar-custom"
              >
                <i class="far fa-user-friends fa-2x" /> </span></span>
            </div>
            <div class="media-body my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ data ? data.depfirst_count.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : 0 }}
              </h4>
              <p class="card-text font-small-3 mb-0 text-muted">
                สมาชิกใหม่ฝากแรก
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-xl-6 mt-2">
          <div class="media">
            <div class="media-aside align-self-start">
              <span
                class="b-avatar badge-light-dark rounded-circle"
                style="width: 48px; height: 48px"
              ><span
                class="b-avatar-custom"
              >
                <i class="far fa-user-friends fa-2x" /> </span></span>
            </div>
            <div class="media-body my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ data ? data.todayNoDepMembers.total.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : 0 }}
              </h4>
              <p class="card-text font-small-3 mb-0 text-muted">
                สมาชิกใหม่ที่ยังไม่ฝาก (วันนี้)
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import {
  BRow, BCol, BTable, BButton,
  BThead,
  BTr,
  BTd,
  BTh,
  BTbody,
  BTableSimple,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTd,
    BTh,
    BButton,
  },
  props: ['data', 'agent'],
  data() {
    return {
      now: moment.tz('Asia/Bangkok').format('DD/MM/YYYY HH:ss'),
    }
  },
}
</script>
