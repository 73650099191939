<template>
  <div>
    <b-row class="match-height h-100">
      <b-col md="6">
        <div class="card h-100">
          <b-card-body class="pb-0 p-50">
            <p class="mb-0">
              <i class="far fa-angle-double-up text-success" /> รายการฝากวันนี้
            </p>
            <span><span class="text-success font-medium-2">{{ data && data.todayDep ? data.todayDep.length : 0 }}</span>
              รายการ</span>
            <div class="truncate">
              <h2 class="mb-25 font-weight-bolder text-success">
                {{ data && data.todayDep ? Commas(data.todayDep.reduce((total, item) => total + Number(item.amount), 0)) : 0 }}
                <span style="font-size: 0.95rem">THB</span> |
                {{ data && data.todayDepKR ? Commas(data.todayDepKR.reduce((total, item) => total + Number(item.amount), 0)) : 0 }}
                <span style="font-size: 0.95rem">KRW</span>
              </h2>

            </div>
          </b-card-body>

          <vue-apex-charts
            type="area"
            height="150"
            width="100%"
            :options="chartOptions"
            :series="series"
          />

        </div>
      </b-col>

      <b-col md="6">
        <div class="card h-100">
          <b-card-body class="pb-0 p-50">
            <p class="mb-0">
              <i class="far fa-angle-double-up text-danger" /> รายการถอนวันนี้
            </p>
            <span><span class="text-danger font-medium-2">{{ data && data.todayWit ? data.todayWit.length : 0 }}</span>
              รายการ</span>
            <div class="truncate">
              <h2 class="mb-25 font-weight-bolder text-danger">
                {{ data && data.todayWit ? Commas(data.todayWit.reduce((total, item) => total + item.amount, 0)) : 0 }} <span style="font-size: 0.95rem">THB</span> |
                {{ data && data.todayWitKR ? Commas(data.todayWitKR.reduce((total, item) => total + item.amount, 0)) : 0 }} <span style="font-size: 0.95rem">KRW</span>
              </h2>

            </div>
          </b-card-body>

          <vue-apex-charts
            type="area"
            height="150"
            width="100%"
            :options="chartOptions"
            :series="series"
          />

        </div>
      </b-col>

    </b-row>
    <b-row>
      <b-col md="6">
        <div class="card h-100">
          <b-card-body class="pb-0 p-50">
            <p class="mb-0">
              <i class="far fa-angle-double-up text-info" /> โบนัส
            </p>

            <div class="truncate">
              <h2 class="mb-25 font-weight-bolder text-info">
                {{ data && data.todayBonus ? Commas(data.todayBonus.reduce((total, item) => total + item.bonus, 0)) : 0 }}  <span style="font-size: 0.95rem">THB</span> |
                {{ data && data.todayBonusKR ? Commas(data.todayBonusKR.reduce((total, item) => total + item.bonus, 0)) : 0 }}  <span style="font-size: 0.95rem">KRW</span>
              </h2>

            </div>
          </b-card-body>

          <vue-apex-charts
            type="area"
            height="150"
            width="100%"
            :options="chartOptions"
            :series="series"
          />

        </div>
      </b-col>

      <b-col md="6">
        <div class="card h-100">
          <b-card-body class="pb-0 p-50">
            <p class="mb-0">
              <i class="far fa-angle-double-up text-warning" /> เกม
            </p>

            <div class="truncate">
              <h2 class="mb-25 font-weight-bolder text-warning">
                {{ data && data.todayGame ? Commas(data.todayGame.reduce((total, item) => total + item.bonus, 0)) : 0 }} <span style="font-size: 0.95rem">THB</span> |
                {{ data && data.todayGameKR ? Commas(data.todayGameKR.reduce((total, item) => total + item.bonus, 0)) : 0 }} <span style="font-size: 0.95rem">KRW</span>
              </h2>

            </div>
          </b-card-body>

          <vue-apex-charts
            type="area"
            height="150"
            width="100%"
            :options="chartOptions"
            :series="series"
          />

        </div>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormSelect,
  BCardBody,
  BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    BRow,
    BCol,
    BCardBody,
    BCardText,
    BFormSelect,
    VueApexCharts,
  },
  props: ['data'],
  data() {
    return {
      selected: null,
      options: [
        { value: null, text: 'ทั้งหมด' },
        { value: 1, text: 'มกราคม' },
        { value: 2, text: 'กุมภาพันธ์' },
        { value: 3, text: 'มีนาคม' },
        { value: 4, text: 'เมษายน' },
        { value: 5, text: 'พฤษภาคม' },
        { value: 6, text: 'มิถุนายน' },
        { value: 7, text: 'กรกฎาคม' },
        { value: 8, text: 'สิงหาคม' },
        { value: 9, text: 'กันยายน' },
        { value: 10, text: 'ตุลาคม' },
        { value: 11, text: 'พฤศจิกายน' },
        { value: 12, text: 'ธันวาคม' },
      ],
      activeChart: 'day',
      series: [
        {
          name: 'Subscribers',
          data: [28, 40, 36, 52, 38, 60, 55],
        },
      ],
      chartOptions: {
        grid: {
          show: false,
          padding: {
            left: 0,
            right: 0,
          },
        },
        chart: {
          toolbar: {
            show: false,
          },
          sparkline: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
          width: 2.5,
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 0.9,
            opacityFrom: 0.5,
            opacityTo: 0.2,
            stops: [0, 80, 100],
          },
        },
        xaxis: {
          type: 'numeric',
          lines: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          labels: { show: false },
        },
        yaxis: [
          {
            y: 0,
            offsetX: 0,
            offsetY: 0,
            padding: {
              left: 0,
              right: 0,
            },
          },
        ],
        tooltip: {
          x: { show: false },
        },
        theme: {
          monochrome: {
            enabled: true,
            color: '#00dd99',
            shadeTo: 'light',
            shadeIntensity: 0.65,
          },
        },
      },
      series2: [{
        name: 'ยอดฝาก',
        data: [31, 40, 28, 51, 42, 109, 100],
      }, {
        name: 'ยอดถอน',
        data: [11, 32, 45, 32, 34, 52, 41],
      }, {
        name: 'กำไรสุทธิ',
        data: [50, 79, 32, 21, 67, 90, 200],
      }],
      chartOptions2: {
        chart: {
          height: 350,
          type: 'area',
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 310,
            position: 'bottom',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        xaxis: {
          type: 'datetime',
          categories: ['2018-09-19T00:00:00.000Z', '2018-09-19T01:30:00.000Z', '2018-09-19T02:30:00.000Z', '2018-09-19T03:30:00.000Z', '2018-09-19T04:30:00.000Z', '2018-09-19T05:30:00.000Z', '2018-09-19T06:30:00.000Z'],
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm',
          },
        },
        legend: {
          position: 'top',
        },
      },
    }
  },
  methods: {
    Commas(x) {
      if (Math.round(x) !== x && x > 0) {
        const Num = Number(x)
        const y = Num.toFixed(2)
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
  },
}
</script>

<style scoped>
.card {
  border-radius: 1rem;
}
</style>
